import { props, state } from 'cerebral'
import { debounce, merge, set } from 'cerebral/factories'
import page from 'page'
import qs from 'qs'
import { omit, propEq, values } from 'ramda'

let app

const routeConfig = [
  ['toRoot', '/'],
  ['toLogin', '/login/:token', { navShown: false }],
  ['toAccountSetting', '/account-settings/:id/:action?', { packageRequired: 'people' }],
  ['toAlbums', '/albums/list/:filter?', { packageRequired: 'albums' }],
  ['toAlbum', '/albums/:id/:action?', { packageRequired: 'albums' }],
  ['toAlerts', '/alerts/list/:filter?', { packageRequired: 'alerts', params: { $sort: { postedAt: -1 } } }],
  ['toAlert', '/alerts/:id/:action?', { packageRequired: 'alerts' }],
  ['toAwards', '/appreciation-awards/list/:recipientProfile?', { packageRequired: 'staff-appreciation', params: { filter: 'new', $sort: { createdAt: -1 } } }],
  ['toAward', '/appreciation-awards/:id/:action?', { packageRequired: 'staff-appreciation' }],
  ['toCapacityLocations', '/capacity-locations/list/:filter?', { packageRequired: 'capacity', params: { $sort: { postedAt: -1 } } }],
  ['toCapacityLocation', '/capacity-locations/:id/:action?', { packageRequired: 'capacity' }],
  ['toCalendar', '/calendars/:id/:action?', { packageRequired: 'events', params: {} }],
  ['toContactInfo', '/contact-info/:id/:action?', { params: {}, packageRequired: 'people' }],
  ['toContactInfoSettings', '/contact-info-settings', { packageRequired: 'people' }],
  ['toContactSettings', '/contact-settings', { packageRequired: 'people' }],
  ['toDisplays', '/displays', {}],
  ['toDisplay', '/displays/:id', {}],
  [
    'toEvents',
    '/events/list/:filter?',
    { packageRequired: 'events', params: { eventType: 'Event', $sort: { startAt: -1 } }, storePath: 'events.lastListView' },
  ],
  [
    'toEventCalendar',
    '/events/calendar/:action?',
    { packageRequired: 'events', params: { eventType: 'Event', filter: 'published' }, storePath: 'events.lastListView' },
  ],
  [
    'toEventReport',
    '/events/report/:action?',
    { packageRequired: 'events', params: { eventType: 'Event', filter: 'report' }, storePath: 'events.lastListView' },
  ],
  ['toEvent', '/events/:id/:action?', { packageRequired: 'events', params: { eventType: 'Event' } }],
  ['toEventAttendances', '/event-attendances', { packageRequired: 'attendance', params: {} }],
  ['toEventAttendance', '/event-attendances/:id', { packageRequired: 'attendance' }],
  ['toEventRsvps', '/event-rsvps', { packageRequired: 'events', params: {} }],
  ['toEventRsvp', '/event-rsvps/:id', { packageRequired: 'events' }],
  ['toEventInstancesSummary', '/event-instances/summary', { packageRequired: 'attendance', params: {} }],
  ['toEventInstances', '/event-instances', { packageRequired: 'attendance', params: { $sort: { date: -1 } } }],
  ['toInvitations', '/invitations/list/:filter?', { packageRequired: 'people', params: { $sort: { invitedAt: -1 } } }],
  ['toInvitationsUpload', '/invitations/upload', { packageRequired: 'bulk-invitation' }],
  ['toInvitation', '/invitations/:id', { packageRequired: 'people' }],
  ['toLibraryItems', '/content-library/list/:provider?', { packageRequired: 'content-library', params: { $sort: { title: 1 } } }],
  ['toLibraryItem', '/content-library/:id', { packageRequired: 'content-library' }],
  ['toLifts', '/lifts', { packageRequired: 'lifts', params: { $sort: { name: 1 } } }],
  ['toLift', '/lifts/:id', { packageRequired: 'lifts' }],
  ['toLocationVehicles', '/vehicles/list/:filter?', { packageRequired: 'vehicles' }],
  ['toLocationVehicleView', '/vehicles/:id/view/:tab?', { packageRequired: 'vehicles' }],
  ['toLocationVehicle', '/vehicles/:id/:action?', { packageRequired: 'vehicles' }],
  ['toLocationVehicleDrivers', '/vehicle-drivers/list/:filter?', { packageRequired: 'people' }],
  ['toLocationVehicleMaintenanceSchedule', '/vehicles-maintenance-schedule/:id/:action?', { packageRequired: 'vehicles' }],
  ['toLocationVehicleShifts', '/vehicle-shifts/list/:filter?', { packageRequired: 'vehicles' }],
  ['toLocationRoutes', '/routes/list/:filter?', { packageRequired: 'vehicles' }],
  ['toLocationRouteView', '/routes/:id/view', { packageRequired: 'vehicles' }],
  ['toLocationRoute', '/routes/:id/:action?', { packageRequired: 'vehicles' }],
  ['toLocationRouteRequests', '/route-requests/list/:filter?', { packageRequired: 'vehicles', params: { $sort: { createdAt: 1 } } }],
  ['toLocationRouteRequestView', '/route-requests/:id/view', { packageRequired: 'vehicles' }],
  ['toLocationRouteRequest', '/route-requests/:id/:action?', { packageRequired: 'vehicles' }],
  ['toLocationRouteDispatchRequests', '/route-dispatch-requests/list/:locationRouteRequest?', { packageRequired: 'vehicles' }],
  ['toMapPoints', '/map-points/list/:filter?', { packageRequired: 'map-points' }],
  ['toMapPoint', '/map-points/:id/:action?', { packageRequired: 'map-points' }],
  ['toMapAreas', '/map-areas/list/:filter?', { packageRequired: 'map-points' }],
  ['toMapArea', '/map-areas/:id/:action?', { packageRequired: 'map-points' }],
  ['toMapCategories', '/map-categories/list/:filter?', { packageRequired: 'map-points' }],
  ['toMapCategorie', '/map-categories/:id/:action?', { packageRequired: 'map-points' }],
  [
    'toMealsCalendar',
    '/meals/calendar/:action?',
    { packageRequired: 'menu', params: { eventType: 'Menu', filter: 'published', $limit: 250 }, storePath: 'events.lastListView' },
  ],
  [
    'toMealsReport',
    '/meals/report/:action?',
    { packageRequired: 'menu', params: { eventType: 'Menu', filter: 'report', $limit: 250 }, storePath: 'events.lastListView' },
  ],
  ['toMealsUpload', '/meals/upload', { packageRequired: 'menu' }],
  ['toMeals', '/meals/list/:filter?', { packageRequired: 'menu', params: { eventType: 'Menu', $sort: { startAt: 1 } }, storePath: 'events.lastListView' }],
  ['toMeal', '/meals/:id/:action?', { packageRequired: 'menu', params: { eventType: 'Menu' } }],
  ['toMealLocation', '/meal-location/:id/:action?', { packageRequired: 'menu', params: { eventType: 'Menu' } }],
  ['toMedias', '/media/list/:filter?', { packageRequired: 'media' }],
  ['toMedia', '/media/:id/:action?', { packageRequired: 'media' }],
  ['toMobileWelcomes', '/welcomes/list/:filter?', { packageRequired: 'notifications' }],
  ['toMobileWelcome', '/welcomes/:id/:action?', { packageRequired: 'notifications' }],
  ['toMobileWelcomeView', '/welcomes/:id/view', { packageRequired: 'notifications' }],
  ['toNotifications', '/notifications/list/:filter?', { packageRequired: 'notifications' }],
  ['toNotificationView', '/notifications/:id/view', { packageRequired: 'notifications' }],
  ['toNotification', '/notifications/:id/:action?', { packageRequired: 'notifications' }],
  ['toOrganization', '/organization', {}],
  ['toPeople', '/people/list/:userTypes?', { packageRequired: 'people', params: { $sort: { 'name.last': 1 } } }],
  ['toPerson', '/people/:id', { packageRequired: 'people' }],
  ['toPlaylists', '/playlists', {}],
  ['toPlaylist', '/playlists/:id', {}],
  ['toPrices', '/prices', { packageRequired: 'pricing' }],
  ['toPrice', '/prices/:id', { packageRequired: 'pricing' }],
  ['toPricings', '/pricings', { packageRequired: 'pricing' }],
  ['toPricing', '/pricings/:id', { packageRequired: 'pricing' }],
  ['toProfile', '/profiles/:id', { packageRequired: 'people' }],
  ['toPublications', '/publications/list/:filter?', { packageRequired: 'publications', params: { eventType: 'Event' } }],
  ['toPublication', '/publications/:id/:action?', { packageRequired: 'publications', params: { eventType: 'Event' } }],
  ['toReservations', '/reservations/list/:filter?', { packageRequired: 'reservations' }],
  ['toReservation', '/reservations/:id?', { packageRequired: 'reservations' }],
  ['toResources', '/resources/list/:resourceGroup?', { packageRequired: 'resources' }],
  ['toResource', '/resources/:id?', { packageRequired: 'resources' }],
  ['toRideProducts', '/ride-products/list', { packageRequired: 'ride-products' }],
  ['toRideProduct', '/ride-products/:id?', { packageRequired: 'ride-products' }],
  ['toRideProductSubscriptions', '/ride-product-subscriptions:', { packageRequired: 'ride-products', params: {} }],
  ['toSettings', '/settings', {}],
  ['toMobileSettings', '/settings/mobile', { packageRequired: 'notifications' }],
  ['toEventSettings', '/settings/event', { packageRequired: 'events' }],
  ['toSnowReports', '/snow-reports/list/:filter?', { packageRequired: 'snow-reports' }],
  ['toSnowReport', '/snow-reports/:id/:action?', { packageRequired: 'snow-reports' }],
  ['toSnowStats', '/snow-stats', { packageRequired: 'snow-stats' }],
  ['toStatusUpdates', '/status-updates/list', { params: { $sort: { createdAt: -1 } } }],
  ['toSurveys', '/surveys', { packageRequired: 'surveys' }],
  ['toSurvey', '/surveys/:id', { packageRequired: 'surveys' }],
  ['toSurveyResponses', '/survey-responses/:survey?', { packageRequired: 'surveys' }],
  ['toTrails', '/trails', { packageRequired: 'trails', params: { $sort: { name: 1 } } }],
  ['toTrail', '/trails/:id', { packageRequired: 'trails' }],
  ['toUpdates', '/updates/list/:filter?', { packageRequired: 'updates', params: { $sort: { postedAt: -1 } } }],
  ['toUpdate', '/updates/:id/:action?', { packageRequired: 'updates' }],
]

page('*', (ctx, next) => {
  ctx.query = qs.parse(ctx.querystring)
  next()
})

page.redirect('/alerts', '/alerts/list/active')
page.redirect('/alerts/list', '/alerts/list/active')
page.redirect('/capacities', '/capacities/list')
page.redirect('/content-library', '/content-library/list')
page.redirect('/invitations', '/invitations/list')
page.redirect('/events', '/events/list/upcoming')
page.redirect('/events/list', '/events/list/upcoming')
page.redirect('/media', '/media/list')
page.redirect('/media/list', '/media/list/today')
page.redirect('/meals', '/meals/list/upcoming')
page.redirect('/meals/list', '/meals/list/upcoming')
page.redirect('/notifications', '/notifications/list/active')
page.redirect('/notifications/list', '/notifications/list/active')
page.redirect('/route-requests/list', '/route-requests/list/requesting')
page.redirect('/people', '/people/list')
page.redirect('/reservations', '/reservations/list/upcoming')
page.redirect('/reservations/list', '/reservations/list/upcoming')
page.redirect('/updates', '/updates/list/active')
page.redirect('/updates/list', '/updates/list/active')
page.redirect('/welcomes/list', '/welcomes/list/draft')

const paramRE = /\/:[a-z_]+\??/gi
// Factory for matching URL patterns to ad hoc sequences.
function route(url, sequence, key) {
  page(url, ({ path, params, query }) => app.runSequence(key, sequence, { params, query, path }))

  const tokens = (url.match(paramRE) || []).reduce((result, token) => {
    const name = token.slice(2, token.endsWith('?') ? -1 : undefined)
    return { ...result, [name]: token }
  }, {})
  const tokenNames = Object.keys(tokens)
  const omitTokenNames = omit(tokenNames)

  const toHref = (params = {}) => {
    let currentUrl = url
    const extraParams = omitTokenNames(params)

    tokenNames.forEach((name) => {
      const token = tokens[name]
      const value = params[name] || ''
      currentUrl = currentUrl.replace(token, value ? `/${value}` : '')
    })

    // Add other parameters as query string.
    if (values(extraParams).filter((v) => v !== undefined && v !== null).length) {
      currentUrl = `${currentUrl}?${qs.stringify(extraParams)}`
    }

    return currentUrl
  }

  const action = ({ props: { params = {} } }) => {
    page.show(toHref(params))
  }

  return { action, toHref }
}

// Set state for path and params.
const createSequence = (key, { navShown = true, navOffscreen = false, params = {}, storePath } = {}) =>
  [
    storePath && set(state`${storePath}`, props`path`),
    merge(state`route.last`, {
      key: state`route.key`,
      path: state`route.path`,
    }),
    merge(state`route`, {
      navShown,
      navOffscreen,
      key,
      path: props`path` || '/',
      params: props`params` || {},
      query: props`query` || {},
    }),
    // Override with configured params for route.
    Object.keys(params).length > 0 && merge(state`route.params`, params),
  ].filter(Boolean)

// Keys are functions taking params and returning paths.
export const routePaths = {}
// Keys are functions taking packagesEnabled and returning access boolean.
export const routeAccess = {}

const routes = routeConfig.reduce((result, [key, path, options = {}]) => {
  const sequence = createSequence(key, options)
  const { action, toHref } = route(path, sequence, key)
  const { packageRequired } = options
  routePaths[key] = toHref
  routeAccess[key] = packageRequired ? propEq(true, packageRequired) : () => true
  return { ...result, [key]: action }
}, {})

export const sequences = {
  toPath: ({ props: { path, query } }) => {
    let queryString = ''
    const [pathOnly, pathQueryString] = path.split('?')
    // Allow query prop to modify path.
    if (query) {
      if (pathQueryString) {
        query = { ...qs.parse(pathQueryString), ...query }
        queryString = qs.stringify(query)
      }
      queryString = '?' + queryString
    }
    page.show(pathOnly + queryString)
  },
  ...routes,
  setSort: set(state`route.params.$sort`, props`sort`),
  setSearch: [
    debounce(250),
    {
      continue: set(state`route.params.$search`, props`search`),
      discard: [],
    },
  ],
  // Use with care for "filter", as this may interfere with routes which use the filter param.
  setParam: set(state`route.params.${props`key`}`, props`value`),
}

export default ({ app: configuredApp }) => {
  app = configuredApp
  app.on('initialized', () => page.start())

  return {
    state: {
      navShown: false,
      path: '/',
      key: 'toLogin',
      params: {},
      last: {},
    },
    sequences,
  }
}
